<script>
	import { onMount } from "svelte";
	import { writable } from "svelte/store";
	import ProductosCatalogoHome from "./ProductosCatalogoHome.svelte";
	import TituloSeccion from "./TituloSeccion.svelte";

	export let posicionSeccion = "left";
	export let bannerSeccion = "";
	export let bannerTexto = "";
	export let tituloSeccion;
	export let iconoClase;
	export let enlaceSeccion;
	export let categoria;

	export let productos = writable([]);
	//console.log("🔑 Token cargado:", import.meta.env.VITE_API_TOKEN);

	onMount(async () => {
		const url = `https://dashboard.qualitypro.cl/api/productos/categoria?categoria_id=${categoria}&limit=3`;
		//console.log("✅ URL de la API generada:", url);

		try {
			console.log("🔄 Iniciando petición a la API...");
			const respuesta = await fetch(url, {
				method: "GET",
				headers: {
					Authorization:
						"Bearer 7|FmBnNSVIZ4DLRpQmI00X7M3GXl351BENdi6sRAYXa73ae2c8",
					"Content-Type": "application/json",
				},
			});

			if (!respuesta.ok) {
				console.error(
					`❌ Error en la API: ${respuesta.status} ${respuesta.statusText}`
				);
				return;
			}

			const data = await respuesta.json();

			if (!data?.data) {
				console.warn(
					"⚠️ API no devuelve 'data'. Respuesta completa:",
					data
				);
			}
			productos.set(data.data);
		} catch (error) {
			console.error("❌ Error al obtener los productos:", error);
		}
	});
</script>

<main class="container mb-5 ps-3">
	<TituloSeccion {tituloSeccion} {iconoClase} {enlaceSeccion} />
	<section class="catalogo ps-5 {posicionSeccion}">
		<div
			class="columna banner rounded-4"
			style="background-image: url('/assets/images/banners-categorias/{bannerSeccion}');"
		>
			<span class="banner-texto">
				<h3>{bannerTexto}</h3>
			</span>
			<img
				src="/assets/images/banners-categorias/{bannerSeccion}"
				alt={tituloSeccion}
				class="img-fluid d-none d-md-block"
			/>
		</div>
		{#each $productos as producto}
			<ProductosCatalogoHome {producto} marginProducto="me-3" />
		{/each}
	</section>
</main>

<style>
	.banner {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.banner-texto {
		display: none;
		position: absolute;
		bottom: 0.25rem;
		left: 0.75rem;
		z-index: 2;
	}
	.banner-texto h3 {
		font-variation-settings: "wght" 800;
		font-size: 1rem;
		color: var(--azul-50);
		text-align: left;
		line-height: 1.25rem;
		text-transform: uppercase;
		padding-right: 1rem;
		letter-spacing: 2px;
		text-shadow: 1px 1px 0 #5eadd2;
	}
	@media (min-width: 1200px) {
		.banner-texto {
			display: none;
			bottom: 1rem;
			left: 1rem;
		}
		.banner-texto h3 {
			font-variation-settings: "wght" 700;
			font-size: 2rem;
			line-height: 2.25rem;
			padding-right: 6rem;
		}
	}
</style>

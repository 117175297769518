<script>
	import { Link } from "svelte-routing";
	export let producto = {};
</script>

<article
	class="columna border border-primary-subtle rounded-4 p-2 position-relative"
>
	<div
		class="d-flex justify-content-center align-items-center imagen-producto mb-2"
	>
		<img
			src="https://qualitypro.asiquedo.cl/back/fotos/{producto.url_foto.startsWith(
				'/'
			)
				? producto.url_foto.substring(1)
				: producto.url_foto}"
			alt={producto.nombre}
			class="img-fluid"
		/>
	</div>
	<span class="text-success text-uppercase small d-block mb-1"
		>SKU: {producto.producto_id}</span
	>
	<h2 class="text-uppercase fs-5 fw-bold">
		{producto.producto_nombre}
	</h2>
	<span class="position-absolute bottom-0 start-0 end-0 p-2">
		<Link
			to={`/ficha-producto/${producto.producto_id}`}
			class="btn btn-primary text-white rounded-pill w-100 d-flex align-items-center justify-content-center"
		>
			<span class="button-content"
				><i class="bi bi-arrow-right me-1"></i> Ver detalles</span
			></Link
		>
	</span>
</article>

<style>
	.columna .imagen-producto {
		position: relative;
		overflow: hidden;
	}
	.columna .imagen-producto img {
		transform: scale(1);
		transition: all 0.3s ease-in-out;
	}
	.columna:hover .imagen-producto img {
		transform: scale(1.25);
		transition: all 0.3s ease-in-out;
	}
</style>

<script>
	import TituloSeccion from "./TituloSeccion.svelte";
	import FormularioContacto from "./FormularioContacto.svelte";
</script>

<div class="container py-3 my-3">
	<TituloSeccion
		tituloSeccion="Escribenos o Visitanos!"
		iconoClase="bi-envelope-at-fill"
		enlaceSeccion=""
	/>

	<div class="d-flex align-items-center mt-3 mt-md-5 mb-2" id="form-footer">
		<FormularioContacto />
		<div class="mapa w-50 mt-3 mt-md-0 ms-md-5">
			<img
				src="/mapa.png"
				alt=""
				class="img-fluid border rounded border-primary-subtle"
			/>
		</div>
	</div>
</div>

<style>
	@media (max-width: 768px) {
	}
</style>

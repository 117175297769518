<script>
	import { user } from "../store/User";
	import { navigate } from "svelte-routing";

	let email = "";
	let password = "";

	const procesarFormulario = () => {
		if (!email.trim() || !password.trim()) {
			console.log("Campos Vacios");
			return;
		}
		user.setUser({
			email,
			password,
		});
		navigate("/perfil", { replace: true });
	};
</script>

<div class="container pt-3">
	<div class="row">
		<div class="col">
			<h1 class="text-primary">Acceso</h1>
			<form
				on:submit|preventDefault={procesarFormulario}
				class="d-flex w-75"
			>
				<input
					type="text"
					class="form-control me-3"
					placeholder="Ingrese su Email"
					bind:value={email}
				/>
				<input
					type="text"
					class="form-control me-3"
					placeholder="Ingrese su Password"
					bind:value={password}
				/>
				<button type="submit" class="btn btn-primary px-4"
					>Acceder</button
				>
			</form>
		</div>
	</div>
</div>

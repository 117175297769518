<script>
	import SeccionCatalogo from "../components/SeccionCatalogo.svelte";
	import SobreNosotros from "../components/SobreNosotros.svelte";
	import BannerDistribuidores from "../components/BannerDistribuidores.svelte";
	import SeccionClientes from "../components/SeccionClientes.svelte";
	import FormularioConMapa from "../components/FormularioConMapa.svelte";
</script>

<div id="carouselExampleDark" class="carousel carousel-dark slide">
	<div class="carousel-indicators">
		<button
			type="button"
			data-bs-target="#carouselExampleDark"
			data-bs-slide-to="0"
			class="active"
			aria-current="true"
			aria-label="Slide 1"
		></button>
		<button
			type="button"
			data-bs-target="#carouselExampleDark"
			data-bs-slide-to="1"
			aria-label="Slide 2"
		></button>
		<button
			type="button"
			data-bs-target="#carouselExampleDark"
			data-bs-slide-to="2"
			aria-label="Slide 3"
		></button>
	</div>
	<div class="carousel-inner">
		<div class="carousel-item active" data-bs-interval="10000">
			<img src="/sliders-blue.jpg" class="d-block w-100" alt="..." />
			<div class="carousel-caption d-none d-md-block">
				<h5>First slide label</h5>
				<p>
					Some representative placeholder content for the first slide.
				</p>
			</div>
		</div>
		<div class="carousel-item" data-bs-interval="2000">
			<img src="/sliders-green.jpg" class="d-block w-100" alt="..." />
			<div class="carousel-caption d-none d-md-block">
				<h5>Second slide label</h5>
				<p>
					Some representative placeholder content for the second
					slide.
				</p>
			</div>
		</div>
		<div class="carousel-item">
			<img
				src="/sliders-green.jpg"
				class="d-block w-100"
				alt="..."
				style="filter: grayscale(100%);"
			/>
			<div class="carousel-caption d-none d-md-block">
				<h5>Third slide label</h5>
				<p>
					Some representative placeholder content for the third slide.
				</p>
			</div>
		</div>
	</div>
	<button
		class="carousel-control-prev"
		type="button"
		data-bs-target="#carouselExampleDark"
		data-bs-slide="prev"
	>
		<span class="carousel-control-prev-icon" aria-hidden="true"></span>
		<span class="visually-hidden">Previous</span>
	</button>
	<button
		class="carousel-control-next"
		type="button"
		data-bs-target="#carouselExampleDark"
		data-bs-slide="next"
	>
		<span class="carousel-control-next-icon" aria-hidden="true"></span>
		<span class="visually-hidden">Next</span>
	</button>
</div>

<BannerDistribuidores />

<SeccionCatalogo
	tituloSeccion="Avícolas"
	iconoClase="icon icon-gallina"
	bannerSeccion="bg-gallinas.webp"
	bannerTexto="Aves Fuerte y Sanas"
	enlaceSeccion="/categoria/9"
	categoria="9"
/>
<SeccionCatalogo
	tituloSeccion="Apicolas"
	iconoClase="icon icon-abeja"
	bannerSeccion="bg-abejas.webp"
	bannerTexto="Suplementos para Colmenas"
	enlaceSeccion="/categoria/16"
	posicionSeccion="right"
	categoria="16"
/>
<SeccionCatalogo
	tituloSeccion="Bovinos"
	iconoClase="icon icon-vaca"
	bannerSeccion="bg-bovinos.webp"
	bannerTexto="Nutrición Bovina Premium"
	enlaceSeccion="/categoria/11"
	categoria="11"
/>
<SeccionCatalogo
	tituloSeccion="Equinos"
	iconoClase="icon icon-caballo"
	bannerSeccion="bg-caballos.webp"
	bannerTexto="Energía Equina Total"
	enlaceSeccion="/categoria/12"
	posicionSeccion="right"
	categoria="12"
/>
<SeccionClientes />
<SobreNosotros />
<FormularioConMapa />

<script>
	import TituloSeccion from "../components/TituloSeccion.svelte";
	import FormularioContacto from "../components/FormularioContacto.svelte";
</script>

<div class="container py-3 my-3">
	<TituloSeccion
		tituloSeccion="Escribenos o Visitanos!"
		iconoClase="bi-envelope-at-fill"
		enlaceSeccion=""
	/>

	<div class="d-flex mt-5 mb-5">
		<FormularioContacto />
		<div class="info-contacto flex-grow-1 ms-3">
			<div class="info-box rounded mb-3">
				<h4 class="fw-bold">Dirección</h4>
				<p class="m-0">
					Balmaceda 2762 Peñaflor en la Región Metropolitana
				</p>
			</div>
			<div class="info-box rounded mb-3">
				<h4 class="fw-bold">Horario</h4>
				<p class="m-0">De Lunes a Viernes de 09:00 a 18:00 hrs</p>
			</div>
			<div class="info-box rounded mb-3">
				<h4 class="fw-bold">Correo</h4>
				<p class="m-0">contacto@qualitypro.cl</p>
			</div>
			<div class="info-box rounded mb-3">
				<h4 class="fw-bold">Télefonos</h4>
				<p class="m-0">(2) 2814 4705</p>
			</div>
		</div>
	</div>

	<div id="donde-estamos"></div>
	<TituloSeccion
		tituloSeccion="Donde Estamos"
		iconoClase="bi-geo-alt-fill"
		enlaceSeccion=""
	/>
	<div class="mapa pb-5 rounded">
		<iframe
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.1343890101157!2d-70.87001412336679!3d-33.62775730661605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662e128e9d8809b%3A0x3f05469246bad8e7!2sQuality%20Pro%20SpA%20%3A%3A%20Salud%20y%20Nutricion%20Animal!5e0!3m2!1ses!2scl!4v1716703233181!5m2!1ses!2scl"
			width="100%"
			height="450"
			style="border:0;"
			allowfullscreen=""
			loading="lazy"
			class="rounded"
			title="Mapa de ubicación de Quality Pro SpA - Salud y Nutricion Animal"
			referrerpolicy="no-referrer-when-downgrade"
		></iframe>
	</div>
</div>

<style>
	.info-contacto .info-box {
		background-color: var(--azul-100);
		border-left: 0.5rem solid var(--azul-600);
		padding: 1.15rem 1rem;
	}
	.info-contacto .info-box h4 {
		font-size: 1.25rem;
		font-variation-settings: "wght" 500;
		margin-bottom: 0;
	}
</style>

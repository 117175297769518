<script>
	import SeccionCatalogo from "../components/SeccionCatalogo.svelte";
	import SobreNosotros from "../components/SobreNosotros.svelte";
	import BannerDistribuidores from "../components/BannerDistribuidores.svelte";
	import SeccionClientes from "../components/SeccionClientes.svelte";
	import FormularioConMapa from "../components/FormularioConMapa.svelte";
</script>

<div id="carouselExampleDark" class="carousel carousel-dark slide">
	<div class="carousel-inner">
		<div class="carousel-item active" data-bs-interval="10000">
			<img
				src="/assets/images/banners/bg-vacas-2100x593px.webp"
				class="d-block w-100"
				alt="..."
			/>
			<!--
			<div class="carousel-caption d-none d-md-block">
				<h5>First slide label</h5>
				<p>
					Some representative placeholder content for the first slide.
				</p>
			</div>-->
		</div>
	</div>
	<!--
	<button
		class="carousel-control-prev"
		type="button"
		data-bs-target="#carouselExampleDark"
		data-bs-slide="prev"
	>
		<span class="carousel-control-prev-icon" aria-hidden="true"></span>
		<span class="visually-hidden">Previous</span>
	</button>
	<button
		class="carousel-control-next"
		type="button"
		data-bs-target="#carouselExampleDark"
		data-bs-slide="next"
	>
		<span class="carousel-control-next-icon" aria-hidden="true"></span>
		<span class="visually-hidden">Next</span>
	</button> -->
</div>

<BannerDistribuidores />

<SeccionCatalogo
	tituloSeccion="Aves"
	iconoClase="icon icon-gallina"
	bannerSeccion="banners-aves.png"
	bannerTexto="Aves Fuerte y Sanas"
	enlaceSeccion="/categoria/9"
	categoria="9"
/>
<SeccionCatalogo
	tituloSeccion="Caballos"
	iconoClase="icon icon-caballo"
	bannerSeccion="banners-caballos.png"
	bannerTexto="Energía Equina Total"
	enlaceSeccion="/categoria/12"
	posicionSeccion="right"
	categoria="12"
/>
<SeccionCatalogo
	tituloSeccion="Perros y Gatos"
	iconoClase="icon icon-mascotas"
	bannerSeccion="banners-mascotas.png"
	bannerTexto="Nutrición Bovina Premium"
	enlaceSeccion="/categoria/13"
	categoria="13"
/>
<SeccionCatalogo
	tituloSeccion="Cerdos"
	iconoClase="icon icon-cerdo"
	bannerSeccion="banners-cerdos.png"
	bannerTexto="Suplementos para Colmenas"
	enlaceSeccion="/categoria/15"
	posicionSeccion="right"
	categoria="15"
/>
<SeccionCatalogo
	tituloSeccion="Vacas"
	iconoClase="icon icon-vaca"
	bannerSeccion="banners-vacas.png"
	bannerTexto="Nutrición Bovina Premium"
	enlaceSeccion="/categoria/11"
	categoria="11"
/>
<SeccionCatalogo
	tituloSeccion="Ovejas y Cabras"
	iconoClase="icon icon-cabras"
	bannerSeccion="banners-ovejas-y-cabras.png"
	bannerTexto="Suplementos para Colmenas"
	enlaceSeccion="/categoria/14"
	posicionSeccion="right"
	categoria="14"
/>
<SeccionCatalogo
	tituloSeccion="Abejas"
	iconoClase="icon icon-abeja"
	bannerSeccion="banners-abejas.png"
	bannerTexto="Suplementos para Colmenas"
	enlaceSeccion="/categoria/16"
	posicionSeccion="left"
	categoria="16"
/>

<SeccionCatalogo
	tituloSeccion="Peces"
	iconoClase="icon icon-pez"
	bannerSeccion="banners-peces.png"
	bannerTexto="Suplementos para Colmenas"
	enlaceSeccion="/categoria/10"
	posicionSeccion="right"
	categoria="10"
/>
<SeccionCatalogo
	tituloSeccion="Higiene"
	iconoClase="icon icon-higiene"
	bannerSeccion="banners-higiene.png"
	bannerTexto="Suplementos para Colmenas"
	enlaceSeccion="/categoria/17"
	posicionSeccion="left"
	categoria="17"
/>

<SeccionClientes />
<SobreNosotros />
<FormularioConMapa />

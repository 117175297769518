<script>
	import { link } from "svelte-routing";
</script>

<footer class="bg-azul-gris py-5">
	<div class="container">
		<div class="row">
			<div class="col">
				<img
					src="/assets/images/logo-footer-quality-pro.svg"
					alt=""
					class="img-fluid mb-5"
				/>
				<h4
					class="text-success text-uppercase fs-5 fw-semibold pt-4 pb-0 pt-md-5 pb-md-2"
				>
					Certificaciones
				</h4>
				<div class="d-flex logo-certificaciones">
					<img
						src="/assets/images/certificaciones-quality-pro.png"
						alt=""
						class="img-fluid pe-4"
					/>
				</div>
			</div>
			<div class="col pt-5 pt-mb-0">
				<div class="d-flex">
					<div class="colm me-mb-5 me-5">
						<h4
							class="text-success text-uppercase fs-5 fw-semibold"
						>
							Productos
						</h4>
						<ul class="list-unstyled menu-footer">
							<li>
								<a
									href="/categoria/9"
									class="text-decoration-none">Aves</a
								>
							</li>
							<li>
								<a
									href="/categoria/12"
									class="text-decoration-none">Caballos</a
								>
							</li>
							<li>
								<a
									href="/categoria/13"
									class="text-decoration-none"
									>Perros y Gatos</a
								>
							</li>
							<li>
								<a
									href="/categoria/15"
									class="text-decoration-none">Cerdos</a
								>
							</li>

							<li>
								<a
									href="/categoria/11"
									class="text-decoration-none">Vacas</a
								>
							</li>
							<li>
								<a
									href="/categoria/14"
									class="text-decoration-none"
									>Ovejas y cabras</a
								>
							</li>
							<li>
								<a
									href="/categoria/16"
									class="text-decoration-none">Abejas</a
								>
							</li>
							<li>
								<a
									href="/categoria/10"
									class="text-decoration-none">Peces</a
								>
							</li>
							<li>
								<a
									href="/categoria/17"
									class="text-decoration-none">Higiene</a
								>
							</li>
						</ul>
					</div>
					<div class="colm ms-mb-5 ms-5">
						<h4
							class="text-success text-uppercase fs-5 fw-semibold"
						>
							Servicio al Cliente
						</h4>
						<ul class="list-unstyled menu-footer">
							<li>
								<a
									href="/distribuidores"
									class="text-decoration-none"
									>Ventas Mayoristas</a
								>
							</li>
							<li>
								<a href="./#" class="text-decoration-none"
									>Ventas por Menor</a
								>
							</li>
							<li>
								<a href="/contacto" class="text-decoration-none"
									>Contáctenos</a
								>
							</li>
						</ul>
						<h4
							class="text-success text-uppercase mt-5 fs-5 fw-semibold"
						>
							Estamos en:
						</h4>
						<a href="https://mercadolibre.com/" target="_blank">
							<img
								src="/assets/images/img-mercadolibre.png"
								alt="QualityPro en MercadoLibre"
								class="img-fluid"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<div class="copyright">
	<div class="d-flex">
		<p class="m-0 mx-auto py-3 fs-light text-center">
			<span class="d-none d-md-block fw-bolder"
				>2024 - Todos los derechos reservados -
			</span><b>QualityPro</b>
			<img
				src="/compra-segura.png"
				alt="Sitio web 100% seguro"
				clas="img-fluid ms-4"
			/>
		</p>
	</div>
</div>

<style>
	@media (max-width: 768px) {
		footer {
			text-align: center;
		}
		footer .logo-certificaciones {
			justify-content: center;
		}
		footer .fs-5 {
			font-size: 1rem;
			text-align: left;
		}
		.menu-footer li a {
			font-size: 0.875rem;
			text-align: left;
		}
	}
	.menu-footer li a {
		font-size: 1.1rem;
		color: var(--azul-100) !important;
		padding: 0.25rem 0;
		display: block;
		border-bottom: 1px solid transparent;
		transition: all 0.3s;
	}
	.menu-footer li a:hover,
	.menu-footer li a:focus,
	.menu-footer li a:active {
		color: var(--verde-100) !important;
		text-shadow: 0 0 20px var(--verde-500);
		border-bottom: 1px solid var(--verde-500);
		padding-left: 0.5rem;
	}
	.text-success {
		color: #56b25e !important;
	}
</style>

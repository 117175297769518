<main class="bg-celeste mb-5">
	<div
		class="bg-distribuidores d-flex justify-content-center align-items-center flex-column"
	>
		<div class="container">
			<p class="m-0 text-white text-center fs-4 fw-bold mt-4 px-5">
				Si tienes una tienda de productos veterinarios o agricolas y
				estás interesado en distribuir productos de Quality Pro, por
				favor completa el formulario. Posteriormente, nos pondremos en
				contacto contigo.
			</p>
		</div>
	</div>
</main>
<div class="container pt-3 pb-5">
	<div class="row justify-content-md-center">
		<div class="col-5">
			<form class="mb-3">
				<label for="" class="form-label m-0">Nombre Empresa</label>
				<input
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					placeholder="Nombre de Empresa / Razón Social"
				/>
				<label for="" class="form-label m-0">Rut</label>
				<input
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					placeholder="RUT"
				/>
				<label for="" class="form-label m-0">Giro</label>
				<input
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					placeholder="Giro"
				/>
				<label for="" class="form-label m-0">Comuna</label>
				<select class="form-select mb-5 p-2 rounded-pill">
					<option value="">-- ¿Cómuna? --</option>
					<option value=""></option>
				</select>
				<label for="" class="form-label m-0">Email</label>
				<input
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					placeholder="E-mail"
				/>
				<label for="" class="form-label m-0"
					>Nombre de persona de contacto</label
				>
				<input
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					placeholder="Persona de Contacto"
				/>
				<label for="" class="form-label m-0">Teléfono</label>
				<input
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					placeholder="Número de Telefono"
				/>
				<label for="" class="form-label m-0">Sitio Web (opcional)</label
				>
				<input
					type="text"
					class="form-control mb-5 p-2 rounded-pill"
					placeholder="Sitio web"
				/>
				<label for="" class="form-label m-0"
					>Indicar productos de interés</label
				>
				<textarea
					name=""
					class="form-control mb-5 p-2"
					rows="5"
					placeholder="Indicar productos de su interes"
				></textarea>
				<div class="text-center">
					<button
						type="submit"
						class="btn btn-success ps-4 pe-5 text-uppercase rounded-pill"
					>
						<i class="bi bi-envelope-check-fill me-3"></i>
						Enviar
					</button>
				</div>
			</form>
		</div>
	</div>
</div>

<style>
	.bg-celeste {
		background-color: var(--bg-celeste);
	}
	.bg-distribuidores {
		background-image: url(/assets/images/banners/bg-iconos.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		height: 250px;
	}
	strong {
		font-variation-settings: "wght" 500;
	}
	.text-primary {
		color: var(--azul-500) !important;
	}
	.form-label {
		font-variation-settings: "wght" 600;
		color: var(--azul-500) !important;
		font-size: 1.5rem !important;
	}
	.form-control,
	.form-select {
		background: var(--azul-100) !important;
		border-color: var(--azul-200) !important;
		padding: 0.75rem 1.5rem !important;
		color: var(--azul-950) !important;
	}
	.form-control::placeholder {
		color: var(--azul-500);
		font-variation-settings: "wght" 200;
	}
	.form-control:focus,
	.form-select:focus {
		box-shadow: 0 0 0 0.25rem rgb(68 153 193 / 35%) !important;
	}
	textarea.form-control {
		border-radius: 2rem;
	}
</style>

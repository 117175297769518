<script>
	import TituloSeccion from "./TituloSeccion.svelte";
</script>

<div class="container py-3 my-3" id="quienes-somos">
	<TituloSeccion
		tituloSeccion="Sobre QualityPro"
		iconoClase="icon icon-somos"
		enlaceSeccion=""
	/>

	<div class="row g-0 pt-2 align-items-center">
		<div class="col-auto pb-3 pb-mb-0 pe-md-5">
			<img src="/sobre-qualitypro.png" alt="" class="img-fluid" />
		</div>
		<div class="col">
			<p class="fw-light">
				<strong>QualityPro Ltda</strong>, líder en nutricion animal,
				propone contribuir a un adecuado desarrollo en la industria de
				la nutricion animal en Chile, otorgando soluciones innovadoras y
				eficientes.
			</p>
			<p class="fw-light">
				Nuestros productos son elaborados bajo altos estándares de
				calidad. Trabajamos día a día para continuar perfeccionando
				nuestros procesos productivos e incorporar constantemente en
				ellos los requerimientos y necesidades de nuestros clientes.
			</p>
			<p class="fw-light">
				Como empresa, nos caracteriza la preocupación por nuestros
				procesos productivos, la mantención del personal idóneo, estar
				siempre a la vanguardia, la innovación y la constante búsqueda
				por conseguir los mejores resultados.
			</p>
		</div>
	</div>
</div>

<style>
	@media (max-width: 768px) {
		#quienes-somos p {
			text-align: center;
		}
	}
</style>

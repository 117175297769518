<script>
	import { Link, link, navigate } from "svelte-routing";
	import { user } from "../store/User";

	import FormularioBuscador from "./FormularioBuscador.svelte";

	const cerrarSesion = () => {
		user.setUser(null);
		navigate("/login", { replace: true });
	};
</script>

<header class="py-2">
	<div class="container">
		<div class="d-flex align-items-center justify-content-between">
			<div class="p-2 logo">
				<a href="/" title="QualityPro">
					<img
						src="/logo-qualitypro.svg"
						alt="QualityPro"
						class="img-fluid"
						style="height: 80px;"
					/>
				</a>
			</div>
			<nav class="p-2 ms-auto me-2 mx-md-4 nav-menu">
				<input type="checkbox" name="menu" id="menu" />
				<label for="menu" class="mega-menu d-flex align-items-center">
					<i class="bi bi-list me-1"></i> Menú
				</label>
				<div class="nav__megamenu">
					<label
						class="nav_header d-flex align-items-center"
						for="menu"
					>
						<h2 class="flex-grow-1 m-0">¡Hola!</h2>
						<i class="bi bi-x-circle" id="btnclose"></i>
					</label>
					<ul class="nav__menu_list">
						<li>
							<a
								href="/categoria/9"
								class="d-flex align-items-center"
							>
								<span>
									<i class="icon icon-gallina"></i>
								</span>
								<span>Aves</span>
							</a>
						</li>
						<li>
							<a
								href="/categoria/12"
								class="d-flex align-items-center"
							>
								<span>
									<i class="icon icon-caballo"></i>
								</span>
								<span>Caballos</span>
							</a>
						</li>
						<li>
							<a
								href="/categoria/13"
								class="d-flex align-items-center"
							>
								<span>
									<i class="icon icon-mascotas"></i>
								</span>
								<span>Perros y Gatos</span>
							</a>
						</li>
						<li>
							<a
								href="/categoria/15"
								class="d-flex align-items-center"
							>
								<span>
									<i class="icon icon-cerdo"></i>
								</span>
								<span>Cerdos</span>
							</a>
						</li>
						<li>
							<a
								href="/categoria/11"
								class="d-flex align-items-center"
							>
								<span>
									<i class="icon icon-vaca"></i>
								</span>
								<span>Vacas</span>
							</a>
						</li>
						<li>
							<a href=".#" class="d-flex align-items-center">
								<span>
									<i class="icon icon-cabras"></i>
								</span>
								<span>Ovejas y Cabras</span>
							</a>
						</li>
						<li>
							<a
								href="/categoria/16"
								class="d-flex align-items-center"
							>
								<span>
									<i class="icon icon-abeja"></i>
								</span>
								<span>Abejas</span>
							</a>
						</li>
						<li>
							<a
								href="/categoria/10"
								class="d-flex align-items-center"
							>
								<span>
									<i class="icon icon-pez"></i>
								</span>
								<span>Peces</span>
							</a>
						</li>
						<li>
							<a
								href="/categoria/17"
								class="d-flex align-items-center"
							>
								<span>
									<i class="icon icon-higiene"></i>
								</span>
								<span>Higiene</span>
							</a>
						</li>
					</ul>
				</div>
			</nav>
			<div class="p-2 flex-grow-1">
				<FormularioBuscador />
			</div>
			<div class="p-2">
				<div class="d-flex flex-column ms-2 ms-md-5">
					<div class="info-telefonos">
						<i class="bi bi-telephone-outbound-fill me-md-3"></i>
						<span class="numero-text">(2) 2814 4705</span>
					</div>
					<div class="info-telefonos info-green">
						<i class="bi bi-whatsapp me-3"></i>
						<span class="numero-text">(9) 0000 0000</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
<div class="bg-verde box-informaciones">
	<div class="container py-2">
		<div class="row align-items-center">
			<div class="col">
				<p class="m-0">
					<img
						src="/assets/images/iconos/icono-despacho.svg"
						alt="icono-despacho"
						class="img-fluid"
						style="height: 20px; margin-top: -2px;"
					/> Despachamos a todo Chile.
				</p>
			</div>
			<div class="col-auto text-end">
				<ul class="list-unstyled m-0 menu-informaciones">
					{#if !$user}
						<li>
							<Link to="/">
								<span>Home</span>
								<span>
									<i
										class="bi bi-house-door-fill"
										aria-hidden="true"
									></i>
								</span>
							</Link>
						</li>
						<li>
							<Link to="/quienes-somos">
								<span>Quienes Somos</span>
								<span>
									<i
										class="bi bi-person-vcard-fill"
										aria-hidden="true"
									></i>
								</span>
							</Link>
						</li>
						<li>
							<a href="/#nuestros-clientes">
								<span>Nuestros Clientes</span>
								<span>
									<i
										class="bi bi-people-fill"
										aria-hidden="true"
									></i>
								</span>
							</a>
						</li>
						<li>
							<a href="/contacto/#donde-estamos">
								<span>Donde estamos</span>
								<span>
									<i
										class="bi bi-geo-alt-fill"
										aria-hidden="true"
									></i>
								</span></a
							>
						</li>
						<li>
							<a href="/blog">
								<span>Blog</span>
								<span>
									<i
										class="bi bi-newspaper"
										aria-hidden="true"
									></i>
								</span>
							</a>
						</li>
						<li>
							<Link to="/contacto">
								<span>Contacto</span>
								<span>
									<i
										class="bi bi-envelope-at-fill"
										aria-hidden="true"
									></i>
								</span>
							</Link>
						</li>
					{:else}
						<Link to="/perfil">Perfil</Link>
						<button on:click={cerrarSesion}>Cerrar Sesión</button>
					{/if}
				</ul>
			</div>
		</div>
	</div>
</div>

<style>
	.bg-light {
		background: var(--azul-100) !important;
	}
	.box-informaciones {
		border-bottom: 4px solid white;
		color: white !important;
		font-variation-settings: "wght" 300;
	}
	.mega-menu {
		font-variation-settings: "wght" 500;
		font-size: 1.25rem;
		text-decoration: none;
		color: #2b769f;
	}
	.mega-menu:hover {
		color: #29759e;
		border-bottom: 1px solid --bg-azul-gris;
	}
	.info-telefonos {
		font-variation-settings: "wght" 700;
		font-style: italic;
		font-size: 1.5rem;
		color: var(--azul-500);
	}
	.info-telefonos.info-green {
		color: var(--verde-500) !important;
	}
	@media (max-width: 768px) {
		header {
			padding-bottom: 0;
		}
		header .container .d-flex .p-2 {
			padding: 0 !important;
		}
		header .container .d-flex .logo a {
			display: block;
		}
		header .container .d-flex .bi-list {
			font-size: 2.5rem;
			line-height: 2.5rem;
		}
		header .container .d-flex .bi-telephone-outbound-fill {
			font-size: 2rem;
			line-height: 2rem;
		}
		header .container .d-flex .bi-telephone-outbound-fill {
			display: flex;
			align-items: center;
		}
		header .container .nav-menu {
			order: 0;
		}
		header .container .flex-grow-1,
		.info-telefonos .bi-whatsapp,
		.info-telefonos .numero-text,
		.menu-informaciones {
			display: none;
		}
		.box-informaciones .col .small {
			text-wrap: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
</style>

<script>
	export let tituloSeccion = ""; // Prop que se puede modificar desde fuera
	export let iconoClase = "";
	export let enlaceSeccion = "";
</script>

<div class="row g-0 mb-3">
	<div class="col">
		<div
			class="d-flex justify-content-between border-2 border-bottom border-success align-items-end"
		>
			<h2 class="text-uppercase text-primary fs-3 fw-bold ps-5">
				{#if iconoClase}
					<i class="{iconoClase} me-2"></i>
				{/if}
				<span>{tituloSeccion}</span>
			</h2>
			{#if enlaceSeccion}
				<a
					href={enlaceSeccion}
					class="btn btn-link text-uppercase fw-bolder">Ver todos</a
				>
			{/if}
		</div>
	</div>
</div>

<style>
	.text-primary {
		color: #0476d9 !important;
	}
	.btn-link {
		color: #29759e !important;
		text-decoration: none;
		font-variation-settings: "wght" 600;
	}
	.btn-link:hover {
		text-decoration: underline;
	}
	.border-success {
		border-bottom: 4px solid #57b15d !important;
	}
	.d-flex {
		position: relative;
	}
	.d-flex::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: -6px; /* Ajusta este valor si necesitas mover la línea hacia arriba o abajo */
		border-top: 10px solid #0476d9; /* Color y grosor de la línea */
		width: 25%;
	}
	.d-flex .fs-3 .icon-pez:before {
		font-size: 3rem;
	}
</style>

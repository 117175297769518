<script>
	import { Router, Route, Link } from "svelte-routing";
	import Header from "./components/Header.svelte";
	import Home from "./views/Home.svelte";
	import QuienesSomos from "./views/QuienesSomos.svelte";
	//import NuestrosClientes from "./views/NuestrosClientes.svelte";
	import Perfil from "./views/Perfil.svelte";
	import Contacto from "./views/Contacto.svelte";
	import Login from "./views/Login.svelte";
	import FichaProducto from "./views/FichaProducto.svelte";
	import CatalogoProductos from "./views/CatalogoProductos.svelte";
	import Buscador from "./views/Buscador.svelte";
	import Distribuidores from "./views/Distribuidores.svelte";

	import Footer from "./components/Footer.svelte";
	import Blog from "./views/Blog.svelte";
	//import FormularioConMapa from "./components/FormularioConMapa.svelte";
	//import { comment } from "svelte/internal";
</script>

<Router>
	<Header />

	<!--<Navbar /> -->

	<Route path="/" component={Home} />
	<Route path="/quienes-somos" component={QuienesSomos} />
	<Route path="/distribuidores" component={Distribuidores} />
	<Route path="/perfil" component={Perfil} />
	<Route path="/blog" component={Blog} />
	<Route path="/contacto" component={Contacto} />
	<Route path="/login" component={Login} />
	<Route path="/ficha-producto/:id" component={FichaProducto} let:params />
	<Route
		path="/categoria/:categoriaId"
		component={CatalogoProductos}
		let:params
	/>
	<Route path="/buscador/" component={Buscador} />

	<Footer />
</Router>

<script>
	import { onMount, onDestroy } from "svelte";
	import { writable } from "svelte/store";

	import ProductoCatalogo from "../components/ProductoCatalogo.svelte";
	import TituloSeccion from "../components/TituloSeccion.svelte";

	let productos = writable([]);
	let searchTerm = "";

	// Obtener el parámetro 's' de la URL en el evento onMount
	onMount(async () => {
		const urlParams = new URLSearchParams(window.location.search);
		searchTerm = urlParams.get("s") || "";

		if (searchTerm) {
			try {
				await fetchProductos();
				// console.log("Término de búsqueda recibido:", searchTerm);
			} catch (error) {
				// console.error("Error al obtener productos:", error);
			}
		} else {
			console.log("No se recibió ningún término de búsqueda.");
		}
	});

	// Función para manejar cambios en la URL
	const handleUrlChange = () => {
		const urlParams = new URLSearchParams(window.location.search);
		searchTerm = urlParams.get("s") || "";
		fetchProductos();
	};

	// Observar cambios en la URL
	window.addEventListener("popstate", handleUrlChange);

	// Fetch de productos
	const fetchProductos = async () => {
		try {
			const url = `https://qualitypro.asiquedo.cl/back/buscarProductos.php?s=${encodeURIComponent(searchTerm)}`;
			let respuesta = await fetch(url);
			respuesta = await respuesta.json();
			productos.set(respuesta.data);
			console.log("Término de búsqueda recibido:", searchTerm);
		} catch (error) {
			console.error("Error al obtener productos:", error);
		}
	};

	// Limpiar listener al desmontar el componente
	onDestroy(() => {
		window.removeEventListener("popstate", handleUrlChange);
	});
</script>

<div class="container mt-4 mb-5">
	{#if $productos.length > 0}
		<TituloSeccion
			tituloSeccion="Buscando : {searchTerm}"
			iconoClase="bi bi-search"
			enlaceSeccion=""
		/>
	{/if}
	<div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
		{#each $productos as producto}
			<ProductoCatalogo {producto} />
		{/each}
	</div>
</div>

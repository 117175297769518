<form class="w-50 me-md-3">
	<input
		type="text"
		class="form-control bg-light border-0 border-bottom border-secondary mb-3 p-2"
		placeholder="Tu nombre"
	/>
	<input
		type="text"
		class="form-control bg-light border-0 border-bottom border-secondary mb-3 p-2"
		placeholder="Tu e-mail"
	/>
	<input
		type="text"
		class="form-control bg-light border-0 border-bottom border-secondary mb-3 p-2"
		placeholder="Tu Telefono"
	/>
	<textarea
		name=""
		id=""
		rows="3"
		class="form-control bg-light border-0 border-bottom border-secondary mb-3 p-2"
		placeholder="Tu Mensaje"
	></textarea>
	<div class="text-end">
		<button type="submit" class="btn btn-primary ps-4 pe-5 text-uppercase">
			<i class="bi bi-envelope-check-fill me-3"></i>
			Enviar
		</button>
	</div>
</form>

<style>
	.form-control {
		background: var(--azul-100) !important;
		border-color: var(--azul-200) !important;
		padding: 0.75rem 1.5rem !important;
		color: var(--azul-950) !important;
	}
	.form-control::placeholder {
		color: var(--azul-500);
		font-variation-settings: "wght" 200;
	}
	.form-control:focus {
		box-shadow: 0 0 0 0.25rem rgb(68 153 193 / 35%) !important;
	}
</style>

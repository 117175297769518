<script>
	import { onMount } from "svelte";
	import BannerDistribuidores from "../components/BannerDistribuidores.svelte";
	import TituloSeccion from "../components/TituloSeccion.svelte";

	export let id;

	let producto = {}; // Variable para almacenar los datos del producto
	onMount(async () => {
		const url = `https://qualitypro.asiquedo.cl/back/obtenerProducto.php?id=${id}`;
		const respuesta = await fetch(url);
		const result = await respuesta.json();
		//console.log(result); // Muestra la respuesta completa de la API para debug
		if (result.status === 1 && result.data) {
			producto = result.data; // Asigna los datos del producto que están en 'data'
		} else {
			console.error(
				"No se encontraron datos para el producto con ID:",
				id
			);
		}
	});
</script>

<div class="bg-white mt-3">
	<div class="container py-2">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb p-0 m-0 text-lowercase">
				<li class="breadcrumb-item small"><a href="/">Home</a></li>
				<li class="breadcrumb-item small active" aria-current="page">
					{producto.producto_nombre}
				</li>
			</ol>
		</nav>
	</div>
</div>

<div class="container">
	<div class="row rows-cols-2 py-3 mb-3" id="ficha-producto">
		<div class="col">
			<div class="d-flex">
				<div class="img-miniaturas" style="width: 100px;">
					{#if producto && producto.url_foto}
						<img
							src="https://qualitypro.asiquedo.cl/back/fotos/{producto.url_foto.startsWith(
								'/'
							)
								? producto.url_foto.substring(1)
								: producto.url_foto}"
							alt={producto.producto_nombre}
							class="border bg-red img-fluid w-100"
							style="height: 100px;"
						/>
					{/if}
				</div>
				<div class="img-grande ms-3">
					{#if producto && producto.url_foto}
						<img
							src="https://qualitypro.asiquedo.cl/back/fotos/{producto.url_foto.startsWith(
								'/'
							)
								? producto.url_foto.substring(1)
								: producto.url_foto}"
							alt={producto.producto_nombre}
							class="border w-100"
							style="height: 450px"
						/>
					{/if}
				</div>
			</div>
		</div>
		<div class="col">
			{#if producto && producto.producto_nombre}
				<h1 class="font-italic fw-bold fs-1">
					{producto.producto_nombre}
				</h1>
			{/if}

			{#if producto && producto.presentacion}
				<dl class="row">
					<dt class="col-sm-3">Presentación</dt>
					<dd class="col-sm-9 fw-light">
						: {producto.presentacion}
					</dd>
				</dl>
			{/if}

			<div class="d-flex justify-content-between">
				<h2 class="text-primary fs-3 fw-bold">Cotizar Online</h2>
			</div>

			<form class="mb-3">
				<input
					type="text"
					class="form-control mb-3 p-2"
					placeholder="Tu nombre"
				/>
				<input
					type="text"
					class="form-control mb-3 p-2"
					placeholder="Tu e-mail"
				/>
				<input
					type="text"
					class="form-control mb-3 p-2"
					placeholder="Tu Telefono"
				/>
				<div class="text-end">
					<button
						type="submit"
						class="btn btn-primary px-5 text-uppercase"
						>Enviar</button
					>
				</div>
			</form>

			<button class="w-100 btn btn-success my-3 py-2 text-uppercase"
				><i class="bi bi-whatsapp me-2"></i> Cotizar por WhatsApp con 1 Click</button
			>
		</div>
	</div>
	<!-- fin row -->

	<TituloSeccion
		tituloSeccion="Descripción del Producto"
		iconoClase="bi-clipboard-data"
		enlaceSeccion=""
	/>

	<div class="row">
		<div class="col">
			<!-- -->
			{#if producto && producto.producto_descripcion}
				<h6 class="fw-bold">Descripción</h6>
				<p>{producto.producto_descripcion}</p>
			{/if}

			{#if producto && producto.analisis_quimico}
				<h6 class="fw-bold">Análisis Quimico</h6>
				<p>{producto.analisis_quimico}</p>
			{/if}

			{#if producto && producto.almacenamiento}
				<h6 class="fw-bold">Almacenamiento</h6>
				<p>{producto.almacenamiento}</p>
			{/if}

			{#if producto && producto.contenido}
				<h6 class="fw-bold">Contenido</h6>
				<p>{producto.contenido}</p>
			{/if}

			{#if producto && producto.dosificacion}
				<h6 class="fw-bold">Dosificación</h6>
				<p>{producto.dosificacion}</p>
			{/if}

			{#if producto && producto.origen}
				<h6 class="fw-bold">Origen</h6>
				<p>{producto.origen}</p>
			{/if}

			{#if producto && producto.estado_fisico}
				<h6 class="fw-bold">Estado Fisico</h6>
				<p>{producto.estado_fisico}</p>
			{/if}

			{#if producto && producto.caracteristicas_microbiologicas}
				<h6 class="fw-bold">Caracteristicas Microbiologicas</h6>
				<p>{producto.caracteristicas_microbiologicas}</p>
			{/if}

			{#if producto && producto.vida_util}
				<h6 class="fw-bold">Vida Útil</h6>
				<p>{producto.vida_util}</p>
			{/if}

			{#if producto && producto.precaucion}
				<h6 class="fw-bold">Precaución</h6>
				<p>{producto.precaucion}</p>
			{/if}

			{#if producto && producto.presentacion}
				<h6 class="fw-bold">Presentación</h6>
				<p>{producto.presentacion}</p>
			{/if}
		</div>
	</div>
</div>

<BannerDistribuidores />

<style>
	@media (max-width: 768px) {
		#ficha-producto .col {
			display: block;
			flex: auto;
		}
		.img-grande {
			margin-bottom: 3rem;
		}
		.img-miniaturas img,
		.img-grande img {
			height: auto !important;
		}
	}
	h1.fs-1 {
		color: var(--azulino);
		border-bottom: 1px solid var(--azul-200);
	}
	.text-primary {
		color: var(--azul-500) !important;
	}
	.form-control {
		background: var(--azul-100) !important;
		border-color: var(--azul-200) !important;
		padding: 0.75rem 1.5rem !important;
		color: var(--azul-950) !important;
	}
	.form-control::placeholder {
		color: var(--azul-500);
		font-variation-settings: "wght" 200;
	}
	.form-control:focus {
		box-shadow: 0 0 0 0.25rem rgb(68 153 193 / 35%) !important;
	}
</style>

<script>
	import TituloSeccion from "./TituloSeccion.svelte";
	import { onMount } from "svelte";

	let logos = [
		{
			src: "/images/clientes/cliente-colun.png",
			alt: "Colun",
			highlighted: false,
		},
		{
			src: "/images/clientes/cliente-covepa.png",
			alt: "Covepa",
			highlighted: false,
		},
		{
			src: "/images/clientes/cliente-cals.png",
			alt: "Cals",
			highlighted: false,
		},
		{
			src: "/images/clientes/cliente-ferosor.png",
			alt: "Ferosor",
			highlighted: false,
		},
		{
			src: "/images/clientes/cliente-calca.png",
			alt: "Calca",
			highlighted: false,
		},
		{
			src: "/images/clientes/cliente-aliagro.png",
			alt: "Aliagro",
			highlighted: false,
		},
		{
			src: "/images/clientes/cliente-cooprinsem.png",
			alt: "Cooprinsem",
			highlighted: false,
		},
	];

	let currentIndex = 3; // Iniciar con el tercer logo como destacado
	let visibleLogos = [];

	function updateVisibleLogos() {
		//console.log("Updating visible logos. Current index:", currentIndex);
		visibleLogos = [];
		let startIndex = (currentIndex - 2 + logos.length) % logos.length;
		//console.log("Start index:", startIndex);
		for (let i = 0; i < 5; i++) {
			let index = (startIndex + i) % logos.length;
			visibleLogos.push({
				...logos[index],
				highlighted: index === currentIndex,
			});
		}
		//console.log("Visible logos:", visibleLogos);
	}

	onMount(() => {
		//console.log("Component mounted. Initial logos:", logos);
		updateVisibleLogos();
		const interval = setInterval(() => {
			currentIndex = (currentIndex + 1) % logos.length;
			//console.log("Interval tick. New current index:", currentIndex);
			updateVisibleLogos();
		}, 2500);
	});
</script>

<div class="container mt-5" id="nuestros-clientes">
	<TituloSeccion
		tituloSeccion="Clientes que nos prefieren"
		iconoClase="bi-award"
		enlaceSeccion=""
	/>

	<div class="d-flex justify-content-center align-items-center mt-3">
		{#each visibleLogos as logo}
			<div class="p-2 flex-fill text-center">
				<img
					src={logo.src}
					alt={logo.alt}
					class={logo.highlighted ? "logo-destacado" : "logo-gris"}
				/>
			</div>
		{/each}
	</div>
</div>

<style>
</style>

<script>
	import { onMount } from "svelte";
	import { writable } from "svelte/store";
	import ProductoCatalogo from "./ProductoCatalogo.svelte";
	import ProductosCatalogoHome from "./ProductosCatalogoHome.svelte";
	import TituloSeccion from "./TituloSeccion.svelte";

	export let posicionSeccion = "left";
	export let bannerSeccion = "";
	export let bannerTexto = "";
	export let tituloSeccion;
	export let iconoClase;
	export let enlaceSeccion;
	export let categoria;

	export let productos = writable([]);

	onMount(async () => {
		const url = `https://qualitypro.asiquedo.cl/back/obtenerProductos.php?categoria_id=${categoria}&limit=3`;
		let respuesta = await fetch(url);
		respuesta = await respuesta.json();
		//let data = respuesta.data;
		$productos = respuesta.data;
		//console.log(data);
	});
</script>

<main class="container">
	<TituloSeccion {tituloSeccion} {iconoClase} {enlaceSeccion} />
	<section class="catalogo {posicionSeccion}">
		<div
			class="columna banner rounded-4"
			style="background-image: url('/assets/images/banners/{bannerSeccion}');"
		>
			<span class="banner-texto">
				<h3>{bannerTexto}</h3>
			</span>
			<img
				src="/assets/images/banners/{bannerSeccion}"
				alt={tituloSeccion}
				class="img-fluid d-none d-md-block"
			/>
		</div>
		{#each $productos as producto}
			<ProductosCatalogoHome {producto} marginProducto="me-3" />
		{/each}
	</section>
</main>

<style>
	.banner {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.banner-texto {
		position: absolute;
		bottom: 0.25rem;
		left: 0.75rem;
		z-index: 2;
	}
	.banner-texto h3 {
		font-variation-settings: "wght" 800;
		font-size: 1rem;
		color: var(--azul-50);
		text-align: left;
		line-height: 1.25rem;
		text-transform: uppercase;
		padding-right: 1rem;
		letter-spacing: 2px;
		text-shadow: 1px 1px 0 #5eadd2;
	}
	@media (min-width: 1200px) {
		.banner-texto {
			bottom: 1rem;
			left: 1rem;
		}
		.banner-texto h3 {
			font-variation-settings: "wght" 700;
			font-size: 2rem;
			line-height: 2.25rem;
			padding-right: 6rem;
		}
	}
</style>

<script>
	import { onMount } from "svelte";
	import { writable } from "svelte/store";
	import ProductoCatalogo from "../components/ProductoCatalogo.svelte";
	import TituloSeccion from "../components/TituloSeccion.svelte";

	export let categoriaId;

	let categoria = {}; // Variable para almacenar los datos del producto
	let productos = writable([]);

	const fetchCategoria = async () => {
		const urlCategoria = `https://qualitypro.asiquedo.cl/back/obtenerCategoria.php?id=${categoriaId}`;
		const respuestaCat = await fetch(urlCategoria);
		const resultCat = await respuestaCat.json();
		if (resultCat.status === 1 && resultCat.data) {
			categoria = resultCat.data; // Asigna los datos de la categoría que están en 'data'
			console.log(categoriaId);
		} else {
			console.error(
				"No se encontraron datos para la categoría con ID:",
				categoriaId
			);
		}
	};

	const fetchProductos = async () => {
		const url = `https://qualitypro.asiquedo.cl/back/obtenerProductos.php?categoria_id=${categoriaId}`;
		let respuesta = await fetch(url);
		respuesta = await respuesta.json();
		productos.set(respuesta.data);
	};

	$: {
		// Cada vez que cambia categoriaId, se ejecutan estas funciones
		fetchCategoria();
		fetchProductos();
	}
</script>

<main class="container mt-4 mb-5">
	{#if categoria}
		<TituloSeccion
			tituloSeccion={categoria.categoria_nombre}
			iconoClase="icon {categoria.categoria_icono}"
			enlaceSeccion=""
		/>
	{/if}
	<div class="catalogo">
		{#each $productos as producto}
			<ProductoCatalogo {producto} />
		{/each}
	</div>
</main>

<style>
	.catalogo {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (min-width: 1300px) {
		main.container {
			max-width: 1240px !important;
			--bs-gutter-x: 0 !important;
		}
		.catalogo {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			gap: 15px;
		}
	}
</style>

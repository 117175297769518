<script>
	import { onMount, onDestroy } from "svelte";
	import { writable } from "svelte/store";

	import ProductoCatalogo from "../components/ProductoCatalogo.svelte";
	import TituloSeccion from "../components/TituloSeccion.svelte";

	let productos = writable([]);
	let searchTerm = "";

	// ✅ Obtener el parámetro 's' de la URL en `onMount`
	onMount(async () => {
		const urlParams = new URLSearchParams(window.location.search);
		searchTerm = urlParams.get("s") || "";
		console.log("🔍 Término de búsqueda recibido:", searchTerm);

		if (searchTerm) {
			try {
				await fetchProductos();
			} catch (error) {
				console.error("❌ Error al obtener productos:", error);
			}
		} else {
			console.log("⚠️ No se recibió ningún término de búsqueda");
		}
	});

	// ✅ Detectar cambios en la URL y volver a buscar
	const handleUrlChange = () => {
		const urlParams = new URLSearchParams(window.location.search);
		let newSearchTerm = urlParams.get("s") || "";

		// Solo buscar si el término cambió
		if (newSearchTerm !== searchTerm) {
			searchTerm = newSearchTerm;
			fetchProductos();
		}
	};

	// ✅ Observar cambios en la URL
	window.addEventListener("popstate", handleUrlChange);

	// ✅ Fetch de productos con la API y Token de seguridad
	const fetchProductos = async () => {
		if (!searchTerm.trim()) {
			console.log("⚠️ No hay término de búsqueda.");
			productos.set([]);
			return;
		}

		try {
			const url = `https://dashboard.qualitypro.cl/api/productos/buscarProductos?s=${encodeURIComponent(searchTerm)}`;
			console.log("✅ URL de la API generada:", url);

			const respuesta = await fetch(url, {
				method: "GET",
				headers: {
					Authorization:
						"Bearer 7|FmBnNSVIZ4DLRpQmI00X7M3GXl351BENdi6sRAYXa73ae2c8",
					"Content-Type": "application/json",
				},
			});

			if (!respuesta.ok) {
				throw new Error(
					`❌ Error en la API: ${respuesta.status} ${respuesta.statusText}`
				);
			}

			const result = await respuesta.json();
			productos.set(result.data || []);
		} catch (error) {
			console.error("❌ Error al obtener productos:", error.message);
			productos.set([]);
		}
	};

	// ✅ Limpiar listener al desmontar el componente
	onDestroy(() => {
		window.removeEventListener("popstate", handleUrlChange);
	});
</script>

<div class="container mt-4 mb-5 px-lg-5">
	{#if $productos.length > 0}
		<TituloSeccion
			tituloSeccion="Buscando : {searchTerm}"
			iconoClase="bi bi-search"
			enlaceSeccion=""
		/>
	{:else}
		<TituloSeccion
			tituloSeccion="No hay resultados para : {searchTerm}"
			iconoClase="bi bi-search"
			enlaceSeccion=""
		/>
	{/if}
	<div class="catalogo">
		{#each $productos as producto}
			<ProductoCatalogo {producto} />
		{/each}
	</div>
</div>

<style>
	.catalogo {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 1200px) {
		/*
		main.container {
			max-width: 1100px !important;
			--bs-gutter-x: 0 !important;
		}*/
		.catalogo {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 40px;
		}
		/*
		.bg-fondo-catalogo .fs-1 {
			font-size: 4.5rem !important;
			font-variation-settings: "wght" 600;
		}*/
	}
</style>

<script>
	import { Link } from "svelte-routing";
	export let producto = {};
</script>

<article class="box-producto border border-primary-subtle rounded-4 p-2">
	<div class="d-flex align-items-center pb-mb-2">
		{#if producto.fotos && producto.fotos.length > 0}
			<img
				src={`https://dashboard.qualitypro.cl/storage/${producto.fotos[0].imagen}`}
				alt={producto.producto_nombre}
				class="img-fluid"
			/>
		{:else}
			<img
				src="https://dashboard.qualitypro.cl/storage/productos/default-imagen.jpg"
				alt="Imagen no disponible"
				class="img-fluid"
			/>
		{/if}
	</div>
	<h2 class="text-uppercase text-center fs-5 mt-3 mb-0 mb-md-3 fw-bold">
		{producto.producto_nombre}
	</h2>
	<span class="position-absolute bottom-0 start-0 end-0 p-2">
		<Link
			to={`/p/${producto.producto_url}`}
			class="btn btn-primary rounded-pill w-100 d-flex align-items-center justify-content-center"
		>
			<span class="button-content"
				><img
					src="/assets/images/iconos/icono-derecha.svg"
					alt={producto.producto_nombre}
					class="img-fluid"
				/> Ver detalles</span
			></Link
		>
	</span>
</article>

<style>
	.box-producto {
		position: relative;
		height: 270px;
	}
	.box-producto h2.fs-5 {
		height: 3.5rem !important;
		color: #009dd3 !important;
		font-size: 1rem !important;
	}

	@media (min-width: 1100px) {
		.box-producto h2.fs-5 {
			height: 4rem !important;
		}
		.box-producto {
			height: 330px !important;
		}
		.box-producto div img {
			/*max-width: 220px !important;*/
			margin: 0 auto;
		}
	}
	.border-primary-subtle {
		/*border-color: var(--azul-500) !important;*/
		border: 4px solid var(--azul-500) !important;
		box-shadow: 0 0 4px #91cceba1;
	}
	.box-producto div {
		position: relative;
		overflow: hidden;
	}
	.box-producto div img {
		transform: scale(1);
		transition: all 0.3s ease-in-out;
	}
	.box-producto:hover img {
		transform: scale(1.25);
		transition: all 0.3s ease-in-out;
	}
	.box-producto:hover {
		box-shadow: 0 0 20px #91cceba1;
	}
</style>

<script>
	import { writable } from "svelte/store";
	import ProductoCatalogo from "../components/ProductoCatalogo.svelte";
	import { onMount } from "svelte";

	export let url_categoria;
	let urlCategoria = url_categoria;

	// Variables reactivas
	let categoria = {}; // Datos de la categoría
	let productos = writable([]); // Lista de productos

	// Si no se recibe `urlCategoria`, detén la ejecución
	if (!urlCategoria) {
		throw new Error("URL de categoría no recibida");
	}

	// Función para obtener datos de la categoría
	const fetchCategoria = async () => {
		try {
			// Nueva URL con el parámetro `url`
			const url = `https://dashboard.qualitypro.cl/api/categorias/buscarIdOUrl?url=${urlCategoria}`;
			console.log("🔍 URL de la API de categoría:", url);

			const respuesta = await fetch(url, {
				method: "GET",
				headers: {
					Authorization:
						"Bearer 7|FmBnNSVIZ4DLRpQmI00X7M3GXl351BENdi6sRAYXa73ae2c8",
					"Content-Type": "application/json",
				},
			});

			if (!respuesta.ok) {
				throw new Error(
					`❌ Error ${respuesta.status}: ${respuesta.statusText}`
				);
			}

			const result = await respuesta.json();
			if (result.status === 1 && result.data) {
				categoria = result.data;
				console.log("✅ Categoría obtenida:", categoria);

				// 🔹 Llamar a fetchProductos solo después de obtener la categoría
				await fetchProductos();
			} else {
				console.error("⚠️ No se encontraron datos para la categoría.");
				categoria = null; // Limpia la categoría si no hay datos
			}
		} catch (error) {
			console.error("❌ Error al obtener la categoría:", error.message);
			categoria = null; // Limpia la categoría en caso de error
		}
	};
	/*
	const fetchCategoria = async () => {
		try {
			const url = `https://qualitypro.asiquedo.cl/back/obtenerCategoria.php?url=${urlCategoria}`;
			const respuesta = await fetch(url);

			if (!respuesta.ok) {
				throw new Error(
					`Error ${respuesta.status}: ${respuesta.statusText}`
				);
			}

			const result = await respuesta.json();
			if (result.status === 1 && result.data) {
				categoria = result.data;
				console.log("✅ Categoría obtenida:", categoria);

				// 🔹 Llamar a fetchProductos solo después de obtener la categoría
				await fetchProductos();
			} else {
				console.error("⚠️ No se encontraron datos para la categoría.");
				categoria = null; // Limpia la categoría si no hay datos
			}
		} catch (error) {
			console.error("❌ Error al obtener la categoría:", error.message);
			categoria = null; // Limpia la categoría en caso de error
		}
	}; */

	// Función para obtener productos de la categoría
	const fetchProductos = async () => {
		if (!categoria?.categoria_id) {
			console.warn(
				"⚠️ No se puede obtener productos, `categoria_id` no está definido aún."
			);
			return;
		}

		try {
			const url = `https://dashboard.qualitypro.cl/api/productos/categoria?categoria_id=${categoria.categoria_id}`;
			console.log("✅ URL de la API generada:", url);

			const respuesta = await fetch(url, {
				method: "GET",
				headers: {
					Authorization:
						"Bearer 7|FmBnNSVIZ4DLRpQmI00X7M3GXl351BENdi6sRAYXa73ae2c8",
					"Content-Type": "application/json",
				},
			});

			if (!respuesta.ok) {
				throw new Error(
					`❌ Error en la API: ${respuesta.status} ${respuesta.statusText}`
				);
			}

			const result = await respuesta.json();

			if (!result?.data) {
				console.warn(
					"⚠️ API no devuelve 'data'. Respuesta completa:",
					result
				);
			}

			// 🔹 Guardar productos en el store (asegurando que sea un array)
			productos.set(result.data || []);
		} catch (error) {
			console.error("❌ Error al obtener los productos:", error.message);
			productos.set([]); // Limpia la store en caso de error
		}
	};

	// Ejecutar la carga de datos cuando cambia `urlCategoria`
	$: if (urlCategoria) {
		fetchCategoria();
	}
</script>

<main
	class="bg-catalogo d-flex justify-content-center align-items-center align-items-stretch mb-5"
	style={categoria?.categoria_bg
		? `background-image: url('https://dashboard.qualitypro.cl/storage/${categoria.categoria_bg}')`
		: ""}
>
	<div
		class="bg-fondo-catalogo d-flex align-items-center justify-content-center"
	>
		<h1 class="m-0 text-white text-center fs-1 fw-bold mt-4 p-5">
			{#if categoria}
				Productos para {categoria.categoria_animal}
			{:else}
				Cargando categoría...
			{/if}
		</h1>
	</div>
</main>

<main class="container mt-4 mb-5 px-lg-5">
	<div class="catalogo">
		{#if $productos.length > 0}
			{#each $productos as producto}
				<ProductoCatalogo {producto} />
			{/each}
		{:else if categoria}
			<p class="text-center mt-5">
				No se encontraron productos para esta categoría.
			</p>
		{:else}
			<p class="text-center mt-5">Cargando productos...</p>
		{/if}
	</div>
</main>

<style>
	.bg-fondo-catalogo {
		width: 100%;
		background: rgba(0, 0, 0, 0.35);
	}
	.bg-catalogo {
		background-position: center;
		background-size: cover;
		height: 400px;
		transition: background-image 0.3s ease-in-out;
	}
	.catalogo {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 1200px) {
		.catalogo {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 40px;
		}
		.bg-fondo-catalogo .fs-1 {
			font-size: 4.5rem !important;
			font-variation-settings: "wght" 600;
		}
	}
</style>

<script>
	import { onMount } from "svelte";
	import { writable } from "svelte/store";
	import ProductoCatalogo from "../components/ProductoCatalogo.svelte";
	import TituloSeccion from "../components/TituloSeccion.svelte";

	export let categoriaId;

	let categoria = {}; // Variable para almacenar los datos del producto
	let productos = writable([]);

	const fetchCategoria = async () => {
		const urlCategoria = `https://qualitypro.asiquedo.cl/back/obtenerCategoria.php?id=${categoriaId}`;
		const respuestaCat = await fetch(urlCategoria);
		const resultCat = await respuestaCat.json();
		if (resultCat.status === 1 && resultCat.data) {
			categoria = resultCat.data; // Asigna los datos de la categoría que están en 'data'
			console.log(categoriaId);
		} else {
			console.error(
				"No se encontraron datos para la categoría con ID:",
				categoriaId
			);
		}
	};

	const fetchProductos = async () => {
		const url = `https://qualitypro.asiquedo.cl/back/obtenerProductos.php?categoria_id=${categoriaId}`;
		let respuesta = await fetch(url);
		respuesta = await respuesta.json();
		productos.set(respuesta.data);
	};

	$: {
		// Cada vez que cambia categoriaId, se ejecutan estas funciones
		fetchCategoria();
		fetchProductos();
	}
</script>

<main
	class="bg-catalogo d-flex justify-content-center align-items-center align-items-stretch mb-5"
>
	<div
		class="bg-fondo-catalogo d-flex align-items-center justify-content-center"
	>
		<h1 class="m-0 text-white text-center fs-1 fw-bold mt-4 p-5">
			{#if categoria}
				Productos para {categoria.categoria_nombre}
			{/if}
		</h1>
	</div>
</main>

<main class="container mt-4 mb-5">
	<div class="catalogo">
		{#each $productos as producto}
			<ProductoCatalogo {producto} />
		{/each}
	</div>
</main>

<style>
	.bg-fondo-catalogo {
		width: 100%;
		background: rgba(0, 0, 0, 0.35);
	}
	.bg-catalogo {
		background-image: url(/assets/images/fondos/bg-catalogo-vacas-2100.jpg);
		background-position: center;
		height: 400px;
	}
	.catalogo {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 1300px) {
		main.container {
			max-width: 1240px !important;
			--bs-gutter-x: 0 !important;
		}
		.catalogo {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 40px;
		}
		.bg-fondo-catalogo .fs-1 {
			font-size: 4.5rem !important;
			font-variation-settings: "wght" 600;
		}
	}
</style>
